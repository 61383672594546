import React from "react";
import "./style.css";

import Footer from "./../../../components/Footer";
import Column from "./../../../components/Column";
import Row from "./../../../components/Row";
import LinkIcon from "./../../../components/LinkIcon";

// get our fontawesome imports
import {
  faGithub,
  faGitlab,
  faTwitter,
  faLinkedin,
  faDiscord,
  faSteam,
  faSpotify,
  faReddit,
  faLastfm,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function HomeFooter() {
  return (
    <Footer>
      <Row>
        <Column>
          <p>© Vilhelm Prytz {new Date().getFullYear()}</p>
        </Column>
        <Column>
          <ul className="icons">
            <li>
              <LinkIcon
                icon={faGithub}
                link="https://github.com/vilhelmprytz"
              />
            </li>
            <li>
              <LinkIcon icon={faGitlab} link="https://gitlab.com/vilhelm" />
            </li>
            <li>
              <LinkIcon
                icon={faTwitter}
                link="https://twitter.com/vilhelmprytz"
              />
            </li>
            <li>
              <LinkIcon
                icon={faLinkedin}
                link="https://www.linkedin.com/in/vilhelm-prytz/"
              />
            </li>
            <li>
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  alert("Lägg till prytz#8523 på Discord");
                }}
                size="lg"
                icon={faDiscord}
              />
            </li>
            <li>
              <LinkIcon
                icon={faSteam}
                link="https://steamcommunity.com/profiles/76561198061006158"
              />
            </li>
            <li>
              <LinkIcon
                icon={faSpotify}
                link="https://open.spotify.com/user/vilhelm.prytz"
              />
            </li>
            <li>
              <LinkIcon
                icon={faReddit}
                link="https://www.reddit.com/user/vprytz"
              />
            </li>
            <li>
              <LinkIcon
                icon={faLastfm}
                link="https://www.last.fm/user/VilhelmPrytz"
              />
            </li>
          </ul>
        </Column>
      </Row>
    </Footer>
  );
}
