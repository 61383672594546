import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function LinkIcon(props) {
  return (
    <a
      href={props.link}
      target={props.newTab ? "_blank" : null}
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon
        size={props.size ? props.size : "lg"}
        icon={props.icon}
      />
    </a>
  );
}

export default LinkIcon;
