import React, { useState, useEffect } from "react";
import "./style.css";

export default function Column(props) {
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (props.right) {
      setStyle((s) => ({ ...s, ...{ textAlign: "right" } }));
    }
    if (props.bottom) {
      setStyle((s) => ({
        ...s,
        ...{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          height: "344px",
        },
      }));
    }
  }, [props.right, props.bottom]);

  return (
    <div className="column" style={style}>
      {props.children}
    </div>
  );
}
