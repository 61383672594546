import React from "react";
import "./App.css";

import Row from "./components/Row";
import Column from "./components/Column";

// page components
import FooterHome from "./pages/Home/Footer";
import LinkIcon from "./components/LinkIcon";

// fontawesome
import { faGithub } from "@fortawesome/free-brands-svg-icons";

function App() {
  return (
    <div className="App">
      <Row>
        <Column>
          <h1>Vilhelm Prytz</h1>
        </Column>
        <Column right>
          <p>
            <a href="mailto:vilhelm@prytznet.se">vilhelm@prytznet.se</a>
          </p>
        </Column>
      </Row>
      <Row>
        <Column>
          <img
            alt="Vilhelm Prytz"
            style={{ height: "300px", width: "100%" }}
            src="images/profile.jpg"
          />
        </Column>
        <Column bottom>
          <h1>Programmerare och Site Reliability Engineer</h1>
        </Column>
      </Row>
      <Row>
        <Column>
          <h4>Utbildning</h4>
          <ul>
            <li>
              KTH,{" "}
              <a href="https://www.kth.se/utbildning/civilingenjor/datateknik">
                Civilingenjörsutbildning i datateknik
              </a>
              , pågående (2022-2027)
            </li>
          </ul>
          <h4>Programmeringsspråk</h4>
          <ul>
            <li>Python</li>
            <li>C</li>
            <li>Rust</li>
            <li>Go</li>
            <li>JavaScript</li>
            <li>Haskell, Prolog</li>
          </ul>
          <h4>Webbutveckling</h4>
          <ul>
            <li>HTML/CSS</li>
            <li>React</li>
            <li>JavaScript</li>
            <li>Flask/Django</li>
          </ul>
          <h4>Programmering och utveckling</h4>
          <ul>
            <li>Git och open-source</li>
            <li>CI/CD (Github Actions, GitLab CI/CD)</li>
            <li>Docker</li>
          </ul>
          <h4>DevOps/SRE</h4>
          <ul>
            <li>Linux och Unix (speciellt Debian)</li>
            <li>Proxmox, Xen, KVM</li>
            <li>Bash</li>
            <li>Ansible</li>
            <li>Puppet</li>
            <li>Terraform</li>
            <li>GitLab</li>
            <li>Prometheus, Alertmanager</li>
            <li>SQL (MySQL, PostgreSQL)</li>
            <li>DNS (bind9, NSD, CoreDNS)</li>
            <li>IP, BGP, peering</li>
          </ul>
        </Column>
        <Column>
          <h1>Erfarenhet</h1>
          <ul>
            <li>
              <h4>Augusti 2022-</h4>
              <p>
                Site Reliability Engineer på Netnod AB (parallellt med pågående
                studier)
              </p>
            </li>
            <li>
              <h4>Juni 2021-Augusti 2022</h4>
              <p>Junior Site Reliability Engineer på Netnod AB</p>
            </li>
            <li>
              <h4>2020-</h4>
              <p>
                Driver konsultfirman Hejduk AB (
                <a
                  href="https://hejduk.se"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.hejduk.se
                </a>
                ) på fritiden, utför programmeringsuppdrag inom webbutveckling
                och systemadministration.
              </p>
            </li>
            <li>
              <h4>2019</h4>
              <p>Sommarjobbade på Netnod.</p>
            </li>
            <li>
              <h4>2018</h4>
              <p>Sommarjobbade på Netnod.</p>
            </li>
            <li>
              <h4>2017</h4>
              <p>Gjorde praktik på Netnod och SVT webcore.</p>
            </li>
            <li>
              <h4>2015-2019</h4>
              <p>
                Drev ett mindre spelserverhostingföretag vid namn SkandiServers.
                Köpte in dedikerad hårdvara och ställde i datacenter
                (co-location) i Stockholm. I arbetet ingick
                systemadministration, support, marknadsföring och försäljning,
                företagsutveckling, webbdesign och allmän
                programvarukonfiguration. Verksamheten lades ner och har blivit
                det som idag är Hejduk AB.
              </p>
            </li>
          </ul>
        </Column>
      </Row>
      <Row>
        <Column>
          <h1>Tidigare projekt</h1>
          <p>
            Projekt som jag tidigare jobbat med. Förutom dessa finns flertalet
            projekt i varierande storlek på min GitHub.{" "}
            <LinkIcon
              icon={faGithub}
              link="https://github.com/vilhelmprytz"
              newTab={true}
            />
          </p>
        </Column>
      </Row>
      <Row>
        <Column>
          <img
            alt="SkandiServers"
            style={{ height: "300px", width: "100%" }}
            src="images/projects/skandiservers.com.png"
          />
        </Column>
        <Column>
          <a
            href="https://wilfredproject.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="SkandiServers"
              style={{ height: "300px", width: "100%" }}
              src="images/projects/wilfredproject.org.png"
            />
          </a>
        </Column>
        <Column>
          <a
            href="https://github.com/hejduk-se/tgy-booking"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Hemsidor och webbsystem för TGDK"
              style={{ height: "300px", width: "100%" }}
              src="images/projects/tgdk.se.png"
            />
          </a>
        </Column>
        <Column>
          <a
            href="https://github.com/vilhelmprytz/pterodactyl-installer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Installationsskript för Pterodactyl"
              style={{ height: "300px", width: "100%" }}
              src="images/projects/pterodactyl-installer.se.png"
            />
          </a>
        </Column>
      </Row>
      <FooterHome />
    </div>
  );
}

export default App;
